import { StateCreator } from "zustand"
import { Membrane, MembraneFilters } from "../../omd-api/membranes/membranes-queries"
import { Indexable, ResourceFilterData } from "../../omd-api/omd"
import { OmdStore } from "../omd-store"

export interface DatasetFiltersSlice<MembraneSchema extends Membrane = Membrane, AllowedPaths extends Indexable = Indexable> {
  datasetFilters: MembraneFilters<MembraneSchema, AllowedPaths>
  setDatasetFilter: (filterName: AllowedPaths, value: ResourceFilterData) => void
  removeDatasetFilter: (filterName: AllowedPaths) => void
  getDatasetFilter: (filterName: AllowedPaths) => ResourceFilterData | undefined
}

type CreateDatasetFiltersSlice =
  <MembraneSchema extends Membrane = Membrane, AllowedPaths extends Indexable = Indexable>(store: OmdStore['currentStore']) =>
    StateCreator<
      OmdStore,
      [["zustand/immer", never]],
      [["zustand/devtools", never]],
      DatasetFiltersSlice<MembraneSchema, AllowedPaths>
    >

export const createDatasetFiltersSlice: CreateDatasetFiltersSlice = (store) => (set, get) => (
  {
    datasetFilters: {},
    setDatasetFilter: (filterName, value) => {
      set(draft => {
        draft[store].datasetFilters[filterName] = value
      })
    },
    removeDatasetFilter: (filterName) => {
      console.log(filterName)
      set((draft) => {
        delete draft[store].datasetFilters[filterName]
      })
    },
    getDatasetFilter: (filterName) => {
      return get()[store].datasetFilters[filterName]
    }
  }
)