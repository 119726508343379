import { useOmdStore } from "../store/omd-store"
import { queryClient } from "./api"
import { fetchRoMembranes, fetchRoMembranesSchema } from "./membranes/ro/ro-queries"
import { fetchSrnfMembranes, fetchSrnfMembranesSchema } from "./membranes/srnf/srnf-queries"
import { fetchReports, fetchReportsSchema } from "./reports/reports-queries"

export const prefetchQueries = async () => {
  await queryClient.fetchQuery(
    ['reports-schema'],
    fetchReportsSchema, {
    staleTime: Infinity,
    cacheTime: Infinity
  }),
    Promise.allSettled(
      [
        queryClient.fetchQuery(
          ['ro-membranes-schema'],
          fetchRoMembranesSchema, {
          staleTime: Infinity,
          cacheTime: Infinity
        }),
        queryClient.fetchQuery(
          ['srnf-membranes-schema'],
          fetchSrnfMembranesSchema, {
          staleTime: Infinity,
          cacheTime: Infinity
        })
      ]
    ).then(r => {
      useOmdStore.getState().ro.setSchema()
      useOmdStore.getState().srnf.setSchema()
    })

  // RO
  queryClient.prefetchQuery(
    ['ro-membranes', {}],
    () => fetchRoMembranes())
  // SRNF
  queryClient.prefetchQuery(
    ['srnf-membranes', {}],
    () => fetchSrnfMembranes())
  // Reports
  queryClient.prefetchQuery(
    ['reports', {}],
    fetchReports)
}