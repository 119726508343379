import { StateCreator } from "zustand"
import { ChartLegend } from "../../components/chart/filters/chart-legend-selector"
import { OmdStore } from "../omd-store"
import { OmdConfig } from "../../config/config"
import { Membrane } from "../../omd-api/membranes/membranes-queries"
import { Indexable } from "../../omd-api/omd"

export interface ChartLegendSlice {
  chartLegend: ChartLegend['path']
  setChartLegend: (chartLegend: ChartLegendSlice['chartLegend']) => void
}

type CreateChartLegendSlice =
<M extends Membrane, P extends Indexable = Indexable>(store: OmdStore['currentStore'], config: OmdConfig<M, P>) =>
    StateCreator<OmdStore, [["zustand/immer", never]], [["zustand/devtools", never]], ChartLegendSlice>
    
export const createChartLegendSlice: CreateChartLegendSlice = (store, config) => (set, get) => (
  {
    chartLegend: config.chart.chartLegend.allChartLegends[0].path,
    setChartLegend: (chartLegend) => set((draft) => { (draft[store].chartLegend = chartLegend) }),
  }
)