import { MembraneSchema as BackendRoMembraneModel } from "../../../../../backend/models/membrane.model"
import { useQuery } from '@tanstack/react-query'
import { buildMembranesQueryFilters, fetchMembranes, fetchMembranesSchema, Membrane, MembraneFilters, MembranePaths, MembraneSchema } from '../membranes-queries'
import { Report } from "../../reports/reports-queries"
import { ResourceSchema } from "../../omd"
import { useOmdStore } from "../../../store/omd-store"

export type RoMembrane = Membrane<BackendRoMembraneModel>

export type RoMembranePaths = MembranePaths<RoMembrane>
export type RoMembranePathsArray = RoMembranePaths[]

export type RoMembranesFilters = MembraneFilters<RoMembrane, RoMembranePaths>

export type RoMembraneAllowedPaths =
  "chemistry" |
  "data.a" |
  "data.ab" |
  "data.b" |
  "data.ca" |
  "data.cpMode" |
  "data.filtrationMode" |
  "data.hydraulicP" |
  "data.naClConcM" |
  "data.otherSolutes" |
  "data.ph" |
  "data.ps" |
  "data.pw" |
  "data.robs" |
  "data.rreal" |
  "dates" |
  "modification" |
  "report.publicationDate" |
  "report.type" |
  "structure"
//"data.pwps"|


// All filters available to the user for RO Membranes
export const roMembraneAllowedPaths: RoMembranePaths[] =
  [
    "chemistry",
    "data.a",
    "data.ab",
    "data.b",
    "data.ca",
    "data.cpMode",
    "data.filtrationMode",
    "data.hydraulicP",
    "data.naClConcM",
    "data.otherSolutes",
    "data.ph",
    "data.ps",
    "data.pw",
    "data.robs",
    "data.rreal",
    "modification",
    "report.publicationDate",
    "report.type",
    "structure",
    //"data.pwps",
  ]

export type RoMembranesQueryKey = 'ro-membranes'
export const queryKey: RoMembranesQueryKey = 'ro-membranes'
export const roMembranesQueryPath = '/membranes'
export type RoMembranesSchemaQueryKey = 'ro-membranes-schema'
export const schemaQueryKey: RoMembranesSchemaQueryKey = 'ro-membranes-schema'
export const roMembranesSchemaQueryPath = roMembranesQueryPath + '/schema'

export const fetchRoMembranes = (params?: RoMembranesFilters) => fetchMembranes<RoMembrane, RoMembranesFilters>('/membranes', params)
export const fetchRoMembranesSchema = () => fetchMembranesSchema<MembraneSchema<RoMembraneAllowedPaths>>('/membranes/schema')

export const useRoMembranesQuery = (params?: RoMembranesFilters) => useQuery(
  [queryKey, params],
  () => fetchRoMembranes(params),
)
export const useRoMembranesSchemaQuery = () => useQuery(
  [schemaQueryKey],
  fetchRoMembranesSchema,
  {
    staleTime: Infinity,
    cacheTime: Infinity
  }
)

export const getExportRoCsvUrl = () => {
  const filters = structuredClone(useOmdStore.getState().srnf.datasetFilters)
  const highlighted = useOmdStore.getState().ro.highlightedItems
  const omdIds = highlighted.map(item => item.omdId)
  if (omdIds.length > 0) {
    //@ts-expect-error
    filters['omdId'] = omdIds
  }
  const builtMembrane = buildMembranesQueryFilters(filters)
  const url = new URL(`${process.env.GATSBY_OMD_API_URL || "http://localhost:3000"}/membranes/export`)
  const searchParams = new URLSearchParams(builtMembrane)
  url.search = searchParams.toString()
  return url.toString()
}