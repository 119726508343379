exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-about-the-open-membrane-database-js": () => import("./../../../src/pages/about-the-open-membrane-database.js" /* webpackChunkName: "component---src-pages-about-the-open-membrane-database-js" */),
  "component---src-pages-calculators-algebraic-water-flux-framework-tsx": () => import("./../../../src/pages/calculators/algebraic-water-flux-framework.tsx" /* webpackChunkName: "component---src-pages-calculators-algebraic-water-flux-framework-tsx" */),
  "component---src-pages-calculators-common-unit-converters-js": () => import("./../../../src/pages/calculators/common-unit-converters.js" /* webpackChunkName: "component---src-pages-calculators-common-unit-converters-js" */),
  "component---src-pages-calculators-concentration-polarization-calculator-js": () => import("./../../../src/pages/calculators/concentration-polarization-calculator.js" /* webpackChunkName: "component---src-pages-calculators-concentration-polarization-calculator-js" */),
  "component---src-pages-calculators-index-js": () => import("./../../../src/pages/calculators/index.js" /* webpackChunkName: "component---src-pages-calculators-index-js" */),
  "component---src-pages-calculators-membrane-performance-calculator-js": () => import("./../../../src/pages/calculators/membrane-performance-calculator.js" /* webpackChunkName: "component---src-pages-calculators-membrane-performance-calculator-js" */),
  "component---src-pages-calculators-osmotic-pressure-calculator-js": () => import("./../../../src/pages/calculators/osmotic-pressure-calculator.js" /* webpackChunkName: "component---src-pages-calculators-osmotic-pressure-calculator-js" */),
  "component---src-pages-concentration-polarization-js": () => import("./../../../src/pages/concentration-polarization.js" /* webpackChunkName: "component---src-pages-concentration-polarization-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-membrane-science-js": () => import("./../../../src/pages/membrane-science.js" /* webpackChunkName: "component---src-pages-membrane-science-js" */),
  "component---src-pages-reverse-osmosis-database-tsx": () => import("./../../../src/pages/reverse-osmosis-database.tsx" /* webpackChunkName: "component---src-pages-reverse-osmosis-database-tsx" */),
  "component---src-pages-srnf-database-tsx": () => import("./../../../src/pages/srnf-database.tsx" /* webpackChunkName: "component---src-pages-srnf-database-tsx" */),
  "component---src-pages-srnf-membrane-tsx": () => import("./../../../src/pages/srnf-membrane.tsx" /* webpackChunkName: "component---src-pages-srnf-membrane-tsx" */),
  "component---src-pages-standardization-of-membrane-performance-testing-js": () => import("./../../../src/pages/standardization-of-membrane-performance-testing.js" /* webpackChunkName: "component---src-pages-standardization-of-membrane-performance-testing-js" */),
  "component---src-pages-submit-a-membrane-js": () => import("./../../../src/pages/submit-a-membrane.js" /* webpackChunkName: "component---src-pages-submit-a-membrane-js" */),
  "component---src-templates-all-articles-js": () => import("./../../../src/templates/all-articles.js" /* webpackChunkName: "component---src-templates-all-articles-js" */),
  "component---src-templates-article-js": () => import("./../../../src/templates/article.js" /* webpackChunkName: "component---src-templates-article-js" */)
}

