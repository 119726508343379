import { ChartLegend } from "../../components/chart/filters/chart-legend-selector";

export const allChartLegends: ChartLegend[] = [
  {
    path: 'chemistry',
    schema: 'membranes',
    key: 'chemistry',
    type: 'string',
  },
  {
    path: 'structure',
    schema: 'membranes',
    key: 'structure',
    type: 'string',
  },
  {
    path: "report.publicationDate",
    schema: 'reports',
    key: "publicationDate",
    type: 'date',
  }
]