import { StateCreator } from "zustand"
import { AxesScaleOption } from "../../components/chart/filters/axes-scale-selector"
import { OmdStore } from "../omd-store"

export interface ChartAxisScaleSlice {
  x: {
    axesScale: AxesScaleOption
    setAxesScale: (xAxesScale: AxesScaleOption) => void
  }
  y: {
    axesScale: AxesScaleOption
    setAxesScale: (yAxesScale: AxesScaleOption) => void
  }
}

type CreateChartAxisScaleSlice =
  (store: OmdStore['currentStore']) =>
    StateCreator<OmdStore, [["zustand/immer", never]], [["zustand/devtools", never]], ChartAxisScaleSlice>

export const createChartAxisScaleSlice: CreateChartAxisScaleSlice = (store) => (set, get) => (
  {
    x: {
      axesScale: 'log',
      setAxesScale: (xAxesScale) => set((draft) => { (draft[store].x.axesScale = xAxesScale) }),
    },
    y: {
      axesScale: 'log',
      setAxesScale: (yAxesScale) => set((draft) => { (draft[store].y.axesScale = yAxesScale) })
    },
  }
)