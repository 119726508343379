import { AxeToPlot } from "../../components/chart/filters/axes-to-plot-selector";
import { SrnfMembrane, SrnfMembraneAllowedPaths } from "../../omd-api/membranes/srnf/srnf-queries";

/**
 * TODO : separate AllowedFilters from AllowedAxesToPlot
 * They are not the same
 */
export const allAxesToPlot: AxeToPlot<SrnfMembrane, SrnfMembraneAllowedPaths>[] = [
    {
        axe: "filtrationResults.permeance",
        axeSchema: "membranes",
    },
    {
        axe: "filtrationResults.rejection",
        axeSchema: "membranes",
    },
    {
        axe: "filtrationResults.mwco",
        axeSchema: "membranes",
    },
    {
        axe: "isaSupportLayer.polymerWeightConcentrationInDopeSolution",
        axeSchema: "membranes",
    },
    {
        axe: "testConditions.hydraulicP",
        axeSchema: "membranes",
    },
    {
        axe: "testConditions.temperature",
        axeSchema: "membranes",
    },
    {
        axe: "filtrationResults.solventPermeance",
        axeSchema: "membranes",
    },
    {
        axe: "report.publicationDate",
        axeSchema: "membranes",
    },
]