import { StateCreator } from "zustand"
import { OmdStore } from "../omd-store"

export type HighlightedItem = { index: number, omdId: string }

export interface HighlightedItemsSlice {
  highlightedItems: HighlightedItem[]
  setHighlightedItems: (items: HighlightedItemsSlice['highlightedItems']) => void
}

type CreateHighlightedItemsSlice =
  (store: OmdStore['currentStore']) =>
    StateCreator<OmdStore, [["zustand/immer", never]], [["zustand/devtools", never]], HighlightedItemsSlice>

export const createHighlightedItemsSlice: CreateHighlightedItemsSlice = (store) => (set, get) => (
  {
    highlightedItems: [],
    setHighlightedItems: (items) => set((draft) => { (draft[store].highlightedItems = items) }),
  }
)