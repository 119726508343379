export const MEMBRANES_SRNF_SOLUTES_NAME = {
  Dye: [
    { name: 'Azobenzene', mw: 182.22, casNumber: '103-33-3' },
    { name: 'Solvent Yellow 7', mw: 198.22, casNumber: '1689-82-3' },
    { name: 'Sudan orange G', mw: 214.22, casNumber: '2051-85-6' },
    { name: 'Chrysoidine G (chloride salt)', mw: 248.71, casNumber: '532-82-1' },
    { name: 'Acridine orange', mw: 265.35, casNumber: '494-38-2' },
    { name: 'Sudan II', mw: 276.33, casNumber: '3118-97-6' },
    { name: 'Neutral red', mw: 288.77, casNumber: '553-24-2' },
    { name: 'Methyl red', mw: 296.3, casNumber: '493-52-7' },
    { name: 'Disperse black 9', mw: 300.36, casNumber: '20721-50-0' },
    { name: 'Disperse red 1', mw: 314.34, casNumber: '2872-52-8' },
    { name: 'Disperse orange 1', mw: 318.33, casNumber: '2581-69-3' },
    { name: 'Nile red', mw: 318.4, casNumber: '7385-67-3' },
    { name: 'Methylene blue (chloride salt)', mw: 319.85, casNumber: '61-73-4' },
    { name: 'Disperse orange 25', mw: 323.35, casNumber: '31482-56-1' },
    { name: 'Methyl orange (sodium salt)', mw: 327.33, casNumber: '547-58-0' },
    { name: 'Disperse red 19', mw: 330.34, casNumber: '2734-52-3' },
    { name: 'Disperse red 13', mw: 348.78, casNumber: '3180-81-2' },
    { name: 'Acid Orange 7 (sodium salt)', mw: 350.32, casNumber: '633-96-5' },
    { name: 'Solvent blue 35', mw: 350.45, casNumber: '17354-14-2' },
    { name: 'Safranine T (chloride salt)', mw: 350.84, casNumber: '477-73-6' },
    { name: 'Sudan Red 7B', mw: 379.46, casNumber: '6368-72-5' },
    { name: 'Acid Yellow 11 (sodium salt)', mw: 380.35, casNumber: '6359-82-6' },
    { name: 'Sudan IV', mw: 380.44, casNumber: '85-83-6' },
    { name: 'Disperse red 1 methacrylate', mw: 382.41, casNumber: '103553-48-6' },
    { name: 'Cresol red', mw: 382.43, casNumber: '1733-12-6' },
    { name: 'Fluorescein-4-isothiocyanate', mw: 389.39, casNumber: '3326-31-6' },
    { name: 'Cresol red (sodium salt)', mw: 404.41, casNumber: '62625-29-0' },
    { name: 'Crystal Violet (chloride salt)', mw: 407.99, casNumber: '548-62-9' },
    { name: 'Oil red O', mw: 408.49, casNumber: '1320-06-5' },
    { name: 'Acid Blue 25 (sodium salt)', mw: 416.38, casNumber: '6408-78-2' },
    { name: 'Eriochrome blue Black R (sodium salt)', mw: 416.38, casNumber: '2538-85-4' },
    { name: 'Disperse red 13 methacrylate', mw: 416.86, casNumber: '82701-58-4' },
    { name: 'Solvent green 3', mw: 418.49, casNumber: '128-80-3' },
    { name: 'Rhodamine B base', mw: 442.55, casNumber: '509-34-2' },
    { name: 'Acid yellow 14 (sodium salt)', mw: 449.24, casNumber: '6359-97-3' },
    { name: 'Orange G (disodium salt)', mw: 452.38, casNumber: '1936-15-8' },
    { name: 'Sunset yellow  FCF (disodium salt)', mw: 452.38, casNumber: '2783-94-0' },
    { name: 'Sudan Black B', mw: 456.54, casNumber: '4197-25-5' },
    { name: 'Eriochrome black T (sodium salt)', mw: 461.38, casNumber: '1787-61-7' },
    { name: 'Sudan 408', mw: 464.6, casNumber: '480438-69-5' },
    { name: 'Indigo carmine (disodium salt)', mw: 466.35, casNumber: '860-22-0' },
    { name: 'Thymol blue', mw: 466.6, casNumber: '76-61-9' },
    { name: 'Primuline (sodium salt)', mw: 475.54, casNumber: '8064-60-6' },
    { name: 'Rhodamine 6G (chloride salt)', mw: 479.01, casNumber: '989-38-8' },
    { name: 'Rhodamine B (chloride salt)', mw: 479.02, casNumber: '81-88-9' },
    { name: 'Thymol blue (sodium salt)', mw: 488.57, casNumber: '62625-21-2' },
    { name: 'Janus green (chloride salt)', mw: 511.06, casNumber: '2869-83-2' },
    { name: 'Protoporphyrin-IX', mw: 562.66, casNumber: '553-12-8' },
    { name: 'Patent Blue VF (trisodium salt)', mw: 566.66, casNumber: '129-17-9' },
    { name: 'Bromopyrogallol red', mw: 574.15, casNumber: '16574-43-9' },
    { name: 'Acid fuchsine (trisodium salt)', mw: 585.5, casNumber: '3244-88-0' },
    { name: 'Amido black (disodium salt)', mw: 616.49, casNumber: '1064-48-8' },
    { name: 'Reactive orange 16 (disodium salt)', mw: 617.54, casNumber: '12225-83-1' },
    { name: 'Bromothymol Blue', mw: 624.38, casNumber: '76-59-5' },
    { name: 'Remazol briljant blue R (disodium salt)', mw: 626.54, casNumber: '2580-78-1' },
    { name: 'Bromothymol Blue (sodium salt)', mw: 646.36, casNumber: '34772-90-2' },
    { name: 'Eosin Y', mw: 647.89, casNumber: '15086-94-9' },
    { name: 'Methyl green (dichloride salt)', mw: 653.24, casNumber: '7114 03-6' },
    { name: 'Eosin Y disodium salt', mw: 691.85, casNumber: '17372-87-1' },
    { name: 'Titan yellow (disodium salt)', mw: 695.72, casNumber: '1829-00-1' },
    { name: 'Congo red (disodium salt)', mw: 696.66, casNumber: '573-58-0' },
    { name: 'Bromocresol green', mw: 698.01, casNumber: '76-60-8' },
    { name: 'Bromocresol green (sodium salt)', mw: 720, casNumber: '62625-32-5' },
    { name: 'Chlorophyllin (trisodium salt)', mw: 724.15, casNumber: '11006-34-1' },
    { name: 'Direct Blue 86 (disodium salt)', mw: 780.2, casNumber: '1330-38-7' },
    { name: 'Briljant blue FCF (trisodium salt)', mw: 792.85, casNumber: '3844-45-9' },
    { name: 'Methyl blue (trisodium salt)', mw: 799.8, casNumber: '28983-56-4' },
    { name: 'Fast green FCF (trisodium salt)', mw: 808.85, casNumber: '2353-45-9' },
    { name: 'Brilliant blue R250 (disodium salt)', mw: 825.97, casNumber: '6104-59-2' },
    { name: 'Erythrosin B', mw: 835.89, casNumber: '16423-68-0' },
    { name: 'Brilliant blue G (disodium salt)', mw: 854.02, casNumber: '6104-58-1' },
    { name: 'Evans blue (tetrasodium salt)', mw: 960.81, casNumber: '314-13-6' },
    { name: 'Erythrosin B (disodium salt)', mw: 879.68, casNumber: '568-63-8' },
    { name: 'Rose Bengal lactone', mw: 973.67, casNumber: '4159-77-7' },
    { name: 'Tetrabromophenol blue', mw: 985.54, casNumber: '4430-25-5' },
    { name: 'Reactive black 5 (tetrasodium salt)', mw: 991.82, casNumber: '17095-24-8' },
    { name: 'Tetrabromophenol blue (sodium salt)', mw: 1007.53, casNumber: '108321-10-4' },
    { name: 'Rose Bengal (disodium salt)', mw: 1017.64, casNumber: '632-69-9' },
    { name: 'Alcian blue 8GX (tetrachloride salt)', mw: 1298.9, casNumber: '33864-99-2' },
    { name: 'Direct red 80 (hexasodium salt)', mw: 1373.07, casNumber: '2610-10-08' },
    { name: 'Reactive green 19 (hexasodium salt)', mw: 1418.93, casNumber: '68110-31-6' },
    { name: 'Reactive red 120 (hexasodium salt)', mw: 1469.98, casNumber: '61951-82-4' },
    { name: 'Acid red 265 (disodium salt)', mw: 635.6, casNumber: '6358-43-6' },
    { name: 'Acid Red 27', mw: 604.48, casNumber: '915-67-3' },
    { name: 'Ferroin', mw: 596.27, casNumber: '14634-91-4' },
    { name: 'Brilliant Yellow', mw: 624.6, casNumber: '3051-11-4' },
  ],
  'Industrial Model Solute': [
    { name: 'Vitamin B12', mw: 1356.4, casNumber: '68-19-9' },
    { name: 'Jacobsen Catalyst', mw: 635.2, casNumber: '138124-32-0' },
    { name: 'Pd-BINAP', mw: 729.1, casNumber: '/' },
    { name: 'Wilkinson catalyst', mw: 925.2, casNumber: '14694-95-2' },
    { name: 'tetracycline', mw: 444.44, casNumber: '60-54-8' },
    { name: 'paracetamol', mw: 151.16, casNumber: '103-90-2' },
    { name: 'ceftazidime', mw: 546.6, casNumber: '72558-82-8' },
    { name: 'L-a-Phosphatidylcholine', mw: 758.1, casNumber: '8002-43-5' },
    { name: 'erythromycin', mw: 733.9, casNumber: '114-07-8' },
    { name: 'beta-carotene', mw: 536.9, casNumber: '7235-40-7' },
    { name: 'clarithromycin', mw: 748, casNumber: '81103-11-9' },
    { name: 'Clindamycin phosphate', mw: 504.96, casNumber: '24729-96-2' },
    { name: '4-nitroaniline', mw: 138.12, casNumber: '100-01-6' },
    { name: 'bisphenol A', mw: 228.29, casNumber: '80-05-7' },
  ],
  Alkanes: [
    { name: 'n-Decane', mw: 142.29, casNumber: '124-18-5' },
    { name: '1-Methyl-naphthalene', mw: 142.2, casNumber: '90-12-0' },
    { name: 'n-Hexadecane', mw: 226.45, casNumber: '544-76-3' },
    { name: '1-Phenylundecane', mw: 232.41, casNumber: '6742-54-7' },
    { name: 'Pristane', mw: 268.53, casNumber: '1921-70-6' },
    { name: 'n-Docosane', mw: 310.61, casNumber: '629-97-0' },
    { name: 'dodecane', mw: 170.33, casNumber: '112-40-3' },
    { name: 'tetradecane ', mw: 198.39, casNumber: '629-59-4' },
    { name: 'hexadecane', mw: 226.44, casNumber: '544-76-3' },
    { name: 'hexacosane', mw: 366.7, casNumber: '630-01-3' },
    { name: 'octadecane', mw: 254.5, casNumber: '593-45-3' },
    { name: 'tetracosane', mw: 338.7, casNumber: '646-31-1' },
    { name: 'octacosane', mw: 394.8, casNumber: '630-02-4' },
    { name: 'hexaphenylbenzene', mw: 534.7, casNumber: '992-04-1' },
    { name: 'N,N-dimethylaminopyridine', mw: 122.17, casNumber: '1122-58-3' },
    { name: 'naphtalene ', mw: 128.17, casNumber: '91-20-3' },
    { name: 'cis-stilbene', mw: 180.24, casNumber: '645-49-8' },
    { name: 'triphenyl methanol', mw: 260.33, casNumber: '76-84-6' },
    { name: '5,5\',6,6\'-tetrahydroxy-3,3,3\',3\'-tetramethyl-1,1\'-spriobisindane', mw: 340.42, casNumber: '77-08-7' },
    { name: 'roxithromycin', mw: 837, casNumber: '80214-83-1' },
  ],
  'Carboxylic Acids': [
    { name: 'myristic acid', mw: 228.37, casNumber: '544-63-8' },
    { name: 'stearic acid', mw: 284.5, casNumber: '57-11-4' },
    { name: 'docosanic acid', mw: 340.6, casNumber: '112-85-6' },
  ],
  Esters: [
    { name: 'butyl acetate', mw: 116.16, casNumber: '123-86-4' },
    { name: 'hexyl acetate', mw: 144.21, casNumber: '142-92-7' },
    { name: 'octyl acetate', mw: 172.27, casNumber: '112-14-1' },
    { name: 'dodecyl acetate', mw: 228.37, casNumber: '112-66-3' },
    { name: '2-ethylbutyl acetate', mw: 144.21, casNumber: '10031-87-5' },
    { name: '2-ethylhexyl acetate', mw: 172.27, casNumber: '103-09-3' },
    { name: '2-isopropyl-5-methylhexyl acetate', mw: 200.32, casNumber: '40853-55-2' },
    { name: 'cyclohexyl acetate', mw: 142.2, casNumber: '622-45-7' },
    { name: '4-tert-butyl-cyclohexyl acetate', mw: 198.3, casNumber: '32210-23-4' },
  ],
  Sugars: [
    { name: 'sucrose', mw: 342.3, casNumber: '57-50-1' },
    { name: 'maltose', mw: 342.3, casNumber: '69-79-4' },
    { name: 'glucose', mw: 180.16, casNumber: '50-99-7' },
    { name: 'raffinose', mw: 504.42, casNumber: '512-69-6' },
    { name: 'A-cyclodextrin', mw: 972.8, casNumber: '10016-20-3' },
    { name: 'Sucrose octaacetate', mw: 678.59, casNumber: '126-14-7' },
  ],
  'QuaternaryAlkyl AmmoniumSalts': [
    { name: 'tetraoctylammonium bromide', mw: 546.8, casNumber: '14866-33-2' },
    { name: 'tetrabutylammonium bromide', mw: 322.37, casNumber: '1643-19-2' },
    { name: 'mepenzolate bromide', mw: 420.3, casNumber: '76-90-4' },
  ],
  Oligomer: [
    { name: 'Polystyrene', mw: 0, casNumber: '100-42-5' },
    { name: 'a-methylstyrene dimer', mw: 236, casNumber: '6144-04-3' },
    { name: 'divinyl benzene', mw: 130.19, casNumber: '1321-74-0' },
    { name: 'Polyethyleneglycol', mw: 0, casNumber: '25322-68-3' },
    { name: 'Ethyleneglycol', mw: 62.07, casNumber: '107-21-1' },
    { name: 'Polypropyleneglycol', mw: 0, casNumber: '25322-69-4' },
  ],
}