import React, { useEffect } from 'react'
import { QueryClient, QueryClientProvider } from "@tanstack/react-query";
import { ReactQueryDevtools } from "@tanstack/react-query-devtools";
import { prefetchQueries } from '../../omd-api/prefetch';
import { queryClient } from '../../omd-api/api';

if (typeof window !== 'undefined')
  prefetchQueries()
const RootLayout: React.FC<{ children: React.ReactNode }> = ({ children }) => {
  return (
    <QueryClientProvider client={queryClient}>
      <ReactQueryDevtools />
      {children}
    </QueryClientProvider>

  );
}
export default RootLayout