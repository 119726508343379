import React from 'react'
import { TableCellData, displayFloat, displayNumberRight, displayScientific, niceArrayValuesJoin, reportLink } from "../../utils/table";

export const allowedData: TableCellData =
{
  "omdId": { _isDefaultVisible: true },
  "report.author.lastName": { _isDefaultVisible: true },
  /* TODO : reenable this */
  /* "report.publicationDate": {
    _isDefaultVisible: true,
    Cell: ({ value }: { value: string }) => new Date(value).getUTCFullYear().toString(),
    _cellProps: { className: displayNumberRight },
  }, */
  "report.type": { _isDefaultVisible: false },
  "report.reference": { _isDefaultVisible: true, Cell: reportLink, sortType: "basic" },
  "report.title": {
    _isDefaultVisible: true,
    _props: { style: { minWidth: "350px" } },
  },
  name: { _isDefaultVisible: false },
  structure: { _isDefaultVisible: true, sortType: "basic" },
  chemistry: {
    _isDefaultVisible: true,
    sortType: "basic",
  },
  chemistryOther: { _isDefaultVisible: true, sortType: "basic" },
  modification: { _isDefaultVisible: false, sortType: "basic" },
  "soluteChoice.solute.name": {
    _isDefaultVisible: true,
    Cell: ({ row }) => {
      if (row.original.soluteChoice.solute1) {
        let names: string[] = []
        for (let i = 1; i <= 15; i++) {
          if (row.original.soluteChoice?.[`solute${i}`]) {
            names.push(row.original.soluteChoice?.[`solute${i}`].name)
          }
        }
        return names.filter((v, pos) => names.indexOf(v) === pos).join((', '))
      } else {
        return ''
      }
    },
  },
}