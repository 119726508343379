import { StateCreator } from "zustand"
import { ChartSizes } from "../../components/chart/filters/chart-size-selector"
import { OmdStore } from "../omd-store"

export interface ChartSizeSlice {
  chartSize: keyof ChartSizes
  setChartSize: (sizeName: ChartSizeSlice['chartSize']) => void
}

type CreateChartSizeSlice =
  (store: OmdStore['currentStore']) =>
    StateCreator<OmdStore, [["zustand/immer", never]], [["zustand/devtools", never]], ChartSizeSlice>
    
export const createChartSizeSlice: CreateChartSizeSlice = (store) => (set, get) => (
  {
    chartSize: 'Rectangle',
    setChartSize: (sizeName) => set((draft) => { (draft[store].chartSize = sizeName) }),
  }
) 