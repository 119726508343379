import { SchemaField as BackendResourceSchemaModelField } from "../../../backend/mixins/repository.mixin.types"
import { utcDateFromYear } from "../utils/date"
import { Pretty, DatesAsStrings } from "../utils/helpers"
import { FieldPath } from "../utils/nested-keys"

export type Indexable = string
export type CommonPaths<Path1 extends Indexable, Path2 extends Indexable> = Path1 extends Path2 ? Path1 : never
export type MergePaths<Path1 extends Indexable, Path2 extends Indexable> = Path1 | Path2

/** 
* Resource Model with common attributes for all resources coming from the API
*/
export type ResourceModel = {
  _id: string, // all have ID
  dates: {
    created: string
    lastUpdate: string
  }
}

/** 
* Allow to describe a specific Resource of the OMD by passing a the specific Resource Schema
*/
export type Resource<R extends ResourceModel = ResourceModel> = R // fucked-up type on hover
export type ResourcePaths<R extends Resource = Resource> = FieldPath<R>
export type ResourceAllowedPaths<R extends Resource, P extends Indexable = ResourcePaths<R>> = CommonPaths<ResourcePaths<R>, P>

/**
 * Filtering
 */
export type ResourceFilterMinMax = { min?: number, max?: number }
export type ResourceFilterData = string | number | ResourceFilterMinMax | string[] | null
export type ResourceFilters<P extends Indexable> = Partial<Record<P, ResourceFilterData>>

const rp: ResourcePaths = '_id'
const rf: ResourceFilters<ResourcePaths> = {
  'dates.created': 'test',
}


/*
* Resource Schema
*/
export type ResourceSchemaFieldTypes = 'Number' | 'Date' | 'Array' | 'String' | 'Boolean'

export interface ResourceSchemaField<Type extends string = ResourceSchemaFieldTypes> extends BackendResourceSchemaModelField {
  type: Type
  labels: {
    short: string,
    shortHtml?: string,
    long: string,
    longHtml?: string,
    unit?: string
    unitHtml?: string
  }
}

export type ResourceSchema<P extends Indexable = Indexable> = {
  [key in P]: ResourceSchemaField
}

/*
* Resource Mongo Queries
*/

export type ResourceMongoQueryKey<RP extends string = string, Prefix extends string = string> =
  RP
  | `${Prefix}${RP}`
  | `${Prefix}${RP}>`
  | `${RP}>`
  | `${Prefix}${RP}<`
  | `${RP}<`

export type ResourceMongoQuery<Key extends string = never> = { [key in ResourceMongoQueryKey<Key>]?: string | number | string[] }

export const buildParam = <Key extends string = never>(filterName: Key, filterData: ResourceFilterData, prefix?: string) => {
  let result: ResourceMongoQuery<Key> = {}
  let key: ResourceMongoQueryKey<Key | ResourcePaths>

  if (filterData === null) return result
  /* if (schema === 'reports') {
    key = `report.${filterName}`
  } else {*/
  key = filterName
  //} 
  if (typeof filterData === 'object' && !Array.isArray(filterData)) {
    // Filter is of type minMax
    if (filterData.min) {
      const min = key === 'report.publicationDate' ? utcDateFromYear(filterData.min) : filterData.min
      result[`${key}>`] = min.toString()
    }
    if (filterData.max) {
      const max = key === 'report.publicationDate' ? utcDateFromYear(filterData.max + 1) : filterData.max
      result[`${key}<`] = max.toString()
    }
    return result
  } else {
    result[key] = filterData
    return result
  }
}
