import React from "react";

export interface TableCellData {
  [key: string]: {
    _isDefaultVisible: boolean,
    Cell?: (({ value }: { value: string }) => string) | (({ value }: { value: number }) => string) | (({ value }: { value: string[] }) => string),
    _cellProps?: { className: string }
    _props?: { style: { [key: string]: string } }
    sortType?: 'basic'
  },
}

export const displayFloat = ({ value }: { value: string }): string => parseFloat(value).toFixed(2);
export const displayScientific = ({ value }: { value: string }): string =>
  value ? parseFloat(value).toExponential(2) : '';
export const reportLink = (data) => {
  const reportLink = data?.row?.original?.report?.link
  if(!reportLink) return data.value
  return <a href={reportLink} target='_blank'>{data.value}</a>
}
export const niceArrayValuesJoin = ({ value }: { value: string[] }): string =>
  value && value.length ? value.join(", ") : "";
export const displayNumberRight = "has-text-right";