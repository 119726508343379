export const getDateFormatted = (date) => {
    const locale = 'en'
    const options = { year: 'numeric', month: 'long', day: 'numeric' }
    return (new Date(date).toLocaleDateString(locale, options))
}

export const dateIsTodayOrInThePast = (date) => {
    const dateDiff = date.setHours(0, 0, 0, 0) - new Date().setHours(0, 0, 0, 0)
    return dateDiff <= 0
}

export const utcDateFromYear = (year: number) => {
    const utcDateFromYear = (new Date(Date.UTC(year, 0))).toISOString()
    return utcDateFromYear
}