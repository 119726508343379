import { ChartLegend } from "../../components/chart/filters/chart-legend-selector";
import { allAxesToPlot } from "./axes-to-plot";

export const allChartLegends: ChartLegend[] = [
  {
    path: 'structure',
    schema: 'membranes',
    key: 'structure',
    type: 'string',
  },
  {
    path: 'chemistry',
    schema: 'membranes',
    key: 'chemistry',
    type: 'string',
  },
  {
    path: "report.publicationDate",
    schema: 'reports',
    key: "publicationDate",
    type: 'date',
  },
  ...allAxesToPlot.map(axeToPlot => {
    return (
      {
        path: axeToPlot.axe.toString(),
        schema: 'membranes',
        key: 'ISNOTUSED ?',
        type: 'number'
      }
    )
  }).filter(cL => cL.path !== 'report.publicationDate'),
  
]