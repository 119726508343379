import { StateCreator } from "zustand"
import { Membrane } from "../../omd-api/membranes/membranes-queries"
import { OmdConfig } from "../../config/config"
import { OmdStore } from "../omd-store"
import { queryClient } from "../../omd-api/api"

export interface MembraneSchemaSlice<MembraneSchema extends Membrane = Membrane> {
  schema?: MembraneSchema
  setSchema: () => void
}

type CreateMembraneSchemaSlice =
  <MembraneSchema extends Membrane = Membrane>(store: OmdStore['currentStore'], config: OmdConfig) =>
    StateCreator<
      OmdStore,
      [["zustand/immer", never]],
      [["zustand/devtools", never]],
      MembraneSchemaSlice<MembraneSchema>
    >

export const createMembraneSchemaSlice: CreateMembraneSchemaSlice = (store, config) => {
  return (set, get) => ({
    schema: queryClient.getQueryData([config.schema.queryKey]),
    setSchema: () => set((draft) => {
      draft[store].schema = queryClient.getQueryData([config.schema.queryKey])
    })
  })
}