import { StateCreator } from "zustand"
import { AxeToPlot } from "../../components/chart/filters/axes-to-plot-selector"
import { OmdConfig } from "../../config/config"
import { OmdStore } from "../omd-store"
import { Membrane } from "../../omd-api/membranes/membranes-queries"
import { Indexable } from "../../omd-api/omd"

export interface AxesToPlotSlice<M extends Membrane, P extends Indexable = Indexable, ATP extends AxeToPlot<M,P> = AxeToPlot<M,P>> {
  axesToPlot: {
    x: ATP['axe']
    y: ATP['axe']
  }
  setXAxeToPlot: (xAxeToPlot: AxesToPlotSlice<M, P>['axesToPlot']['x']) => void
  setYAxeToPlot: (yAxeToPlot: AxesToPlotSlice<M, P>['axesToPlot']['y']) => void
}

type CreateAxesToPlotSlice =
  <M extends Membrane, P extends Indexable = Indexable>(store: OmdStore['currentStore'], config: OmdConfig<M, P>) =>
    StateCreator<OmdStore, [["zustand/immer", never]], [["zustand/devtools", never]], AxesToPlotSlice<M,P>>

export const createAxesToPlotSlice: CreateAxesToPlotSlice = (store, config) => (set, get, subscribe) => (
  {
    axesToPlot: {
      x: config.chart.axesToPlot.allAxesToPlot[0].axe,
      y: config.chart.axesToPlot.allAxesToPlot[1].axe,
    },
    setXAxeToPlot: (xAxeToPlot) => set((draft) => { (draft[store].axesToPlot.x = xAxeToPlot) }),
    setYAxeToPlot: (yAxeToPlot) => set((draft) => { (draft[store].axesToPlot.y = yAxeToPlot) }),
  }
)