import { create } from 'zustand'
import { devtools } from 'zustand/middleware';
import { immer } from 'zustand/middleware/immer'
import { RoMembrane, RoMembraneAllowedPaths } from '../omd-api/membranes/ro/ro-queries';
import { SrnfMembrane, SrnfMembraneAllowedPaths } from '../omd-api/membranes/srnf/srnf-queries';
import { roConfig } from '../config/ro-config';
import { srnfConfig } from '../config/srnf-config';
import { RoMembranesFilters } from './ro-membranes-store';
import { createAxesToPlotSlice } from './slices/axes-to-plot';
import { createChartAxisScaleSlice } from './slices/axis-scale';
import { createChartLegendSlice } from './slices/chart-legend';
import { createChartSizeSlice } from './slices/chart-size';
import { createDatasetFiltersSlice } from './slices/dataset-filters';
import { createHighlightedItemsSlice } from './slices/highlighted-items';
import { SrnfMembranesFilters } from './srnf-membranes-store';
import { get as _get } from 'lodash'
import { createMembraneSchemaSlice } from './slices/membrane-schema';
import { Membrane } from '../omd-api/membranes/membranes-queries';
import { Indexable } from '../omd-api/omd';

export type OmdStore =
  {
    currentStore: 'ro' | 'srnf'
    setCurrentStore: (store: OmdStore['currentStore']) => void
    ro: RoMembranesFilters,
    srnf: SrnfMembranesFilters
  }

export const useOmdStore = create<OmdStore>()(
  devtools(
    immer(
      (set, get, subscribe) => ({
        currentStore: 'srnf',
        setCurrentStore: (store) => set((draft) => { draft.currentStore = store }),
        ro: {
          config: roConfig,
          ...createAxesToPlotSlice<RoMembrane, RoMembraneAllowedPaths>('ro', roConfig)(set, get, subscribe),
          ...createDatasetFiltersSlice<RoMembrane, RoMembraneAllowedPaths>('ro')(set, get, subscribe),
          ...createChartLegendSlice('ro', roConfig)(set, get, subscribe),
          ...createHighlightedItemsSlice('ro')(set, get, subscribe),
          ...createChartSizeSlice('ro')(set, get, subscribe),
          ...createChartAxisScaleSlice('ro')(set, get, subscribe),
          ...createMembraneSchemaSlice<RoMembrane>('ro', roConfig)(set, get, subscribe)
        },
        srnf: {
          config: srnfConfig,
          ...createAxesToPlotSlice('srnf', srnfConfig)(set, get, subscribe),
          ...createDatasetFiltersSlice<SrnfMembrane, SrnfMembraneAllowedPaths>('srnf')(set, get, subscribe),
          ...createChartLegendSlice('srnf',srnfConfig)(set, get, subscribe),
          ...createHighlightedItemsSlice('srnf')(set, get, subscribe),
          ...createChartSizeSlice('srnf')(set, get, subscribe),
          ...createChartAxisScaleSlice('srnf')(set, get, subscribe),
          ...createMembraneSchemaSlice<SrnfMembrane>('srnf', srnfConfig)(set, get, subscribe)
        },
      })
    )
  )
)

export const useCurrentStore = () => {
  const currentStore = useOmdStore(state => state.currentStore)
  return currentStore
}
