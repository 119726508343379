import { TableCellData, displayFloat, displayNumberRight, displayScientific, niceArrayValuesJoin, reportLink } from "../../utils/table";

export const allowedData: TableCellData =
{
  "report.author.lastName": { _isDefaultVisible: true },
  "report.publicationDate": {
    _isDefaultVisible: true,
    Cell: ({ value }: { value: string }) => new Date(value).getUTCFullYear().toString(),
    _cellProps: { className: displayNumberRight },
  },
  "report.type": { _isDefaultVisible: false },
  "report.reference": { _isDefaultVisible: true, Cell: reportLink, sortType: "basic" },
  "report.title": {
    _isDefaultVisible: true,
    _props: { style: { minWidth: "350px" } },
  },
  "data.a": {
    _isDefaultVisible: true,
    Cell: displayFloat,
    _cellProps: { className: displayNumberRight },
  },
  "data.b": {
    _isDefaultVisible: false,
    Cell: displayFloat,
    _cellProps: { className: displayNumberRight },
  },
  "data.ab": {
    _isDefaultVisible: true,
    Cell: displayFloat,
    _cellProps: { className: displayNumberRight },
  },
  "data.rreal": {
    _isDefaultVisible: true,
    Cell: displayFloat,
    _cellProps: { className: displayNumberRight },
  },
  "data.robs": {
    _isDefaultVisible: false,
    Cell: displayFloat,
    _cellProps: { className: displayNumberRight },
  },
  "data.pw": {
    _isDefaultVisible: false,
    Cell: displayScientific,
    _cellProps: { className: displayNumberRight },
  },
  "data.ps": {
    _isDefaultVisible: false,
    Cell: displayScientific,
    _cellProps: { className: displayNumberRight },
  },
  "data.cpMode": {
    _isDefaultVisible: false,
  },
  name: { _isDefaultVisible: false },
  structure: { _isDefaultVisible: true, sortType: "basic" },
  chemistry: {
    _isDefaultVisible: true,
    Cell: niceArrayValuesJoin,
    sortType: "basic",
  },
  chemistryOther: { _isDefaultVisible: true, sortType: "basic" },
  modification: { _isDefaultVisible: false, sortType: "basic" },
  "data.filtrationMode": { _isDefaultVisible: false },
  "data.characterization": {
    _isDefaultVisible: false,
    Cell: niceArrayValuesJoin,
  },
  "data.hydraulicP": {
    _isDefaultVisible: false,
    Cell: displayFloat,
    _cellProps: { className: displayNumberRight },
  },
}