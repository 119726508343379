
import { Pretty } from "../../utils/helpers"
import { fetch, queryClient } from "../api"
import { buildParam, Resource, ResourceMongoQueryKey, ResourcePaths, ResourceFilterData, Indexable, ResourceSchema, ResourceFilters, ResourceModel, ResourceAllowedPaths } from "../omd"
import { Report, ReportSchema, schemaQueryKey as reportsSchemaQueryKey } from "../reports/reports-queries"
import { RoMembrane, RoMembraneAllowedPaths } from "./ro/ro-queries"

/**
 * Generic Membrane
 * All membranes have a name property in common
 */
export type MembraneBaseModel = Resource<{ 'name': string, 'omdId': string } & ResourceModel>

/**
 * A Specific Membrane is an extension of the base MembraneModel with Report populated
 * and some keys really unnecessary we prevent TS bloat
 */
export type Membrane<M extends MembraneBaseModel = MembraneBaseModel> = Resource<
  MembraneBaseModel
  & 
  Omit<M, 'report' | 'creator' | 'history' | 'notes' | 'issues'>
  & 
  { report: Report }
>

export type MembranePaths<M extends Membrane = Membrane> = ResourcePaths<M>
export type MembraneAllowedPaths<M extends Membrane = Membrane, P extends Indexable = MembranePaths> = ResourceAllowedPaths<M, P>
export const membraneBaseModelAllowedPaths: MembraneAllowedPaths[] = ['name', '_id', '']


export type MembraneFilters<M extends Membrane = Membrane, P extends Indexable = MembraneAllowedPaths> = ResourceFilters<P>

export type MembraneSchema<P extends Indexable> = ResourceSchema<P>

export type MembranesMongoQueryKey<MP extends string> = ResourceMongoQueryKey<MP>
export type MembranesMongoQuery<MP extends string> = { [key in MembranesMongoQueryKey<MP>]?: string | number | string[] }

export const fetchMembranes = <M extends Membrane, MF extends MembraneFilters<M>>(path: string, params?: MF) => {
  
  const builtParams = buildMembranesQueryFilters(params)
  return fetch<M[]>(path, { params: { populate: ['report'], ...builtParams } })
}

export const fetchMembrane = <M extends Membrane>(path: string) => {
  return fetch<M[]>(path, { params: { populate: ['report'] } })
}

export const fetchMembranesSchema = <MS extends MembraneSchema<Indexable> = MembraneSchema<MembranePaths>>(path: string) => {
  return fetch<MS>(path).then(membraneSchema => {
    const reportsSchema = queryClient.getQueryData<ReportSchema>([reportsSchemaQueryKey])!
    //We populate report schema into membrane schema
    //Because we always use membranes with report populated
    const membraneSchemaWithPrefixedReportSchema = Object.keys(reportsSchema).reduce(
      (acc, curr) => {
        const newPath = `report.${curr}`
        acc[`report.${curr}`] = {...reportsSchema[curr], path: newPath} // types this ?
        return acc
      },
      membraneSchema
    )
    return membraneSchemaWithPrefixedReportSchema
  })
}

export const buildMembranesQueryFilters = <M extends Membrane, MF extends MembraneFilters<M>>(params?: MF) => {
  let result: { [key: string]: any } = {}
  //Object.entries(params?.reports ?? {}).forEach(([filterName, filterData]) => { result = { ...result, ...buildParam(filterName, filterData) } })
  Object.entries(params ?? {}).forEach(([filterName, filterData]) => { result = { ...result, ...buildParam(filterName, filterData) } })
  return result
}