import { allowedData } from "./srnf/table"
import { allAxesToPlot } from "./srnf/axes-to-plot"
import { allChartLegends } from "./srnf/chart-legends"
import { OmdConfig } from "./config"
import { SrnfMembrane, SrnfMembraneAllowedPaths, schemaQueryKey, srnfMembranesSchemaQueryPath } from "../omd-api/membranes/srnf/srnf-queries"

export const srnfConfig: OmdConfig<SrnfMembrane, SrnfMembraneAllowedPaths> = {
  schema: {
    queryKey: schemaQueryKey,
    path: srnfMembranesSchemaQueryPath
  },
  table: {
    allowedData
  },
  chart: {
    axesToPlot: {
      allAxesToPlot
    },
    chartLegend: { 
      allChartLegends
    }
  }
}