import { QueryClient } from "@tanstack/react-query"
import axios, { AxiosRequestConfig } from "axios"
import { ControllerResponse } from '../../../backend/factories/controller.factory.types'
import { RoMembrane } from "./membranes/ro/ro-queries"
import { prefetchQueries } from "./prefetch"


export const queryClient = new QueryClient()

interface Result<Data> extends ControllerResponse<Data> {
  result: Data,
  ok: number
}

export const api = axios.create({
  baseURL: process.env.GATSBY_OMD_API_URL || "http://localhost:3000",
})

export const fetch = <T = unknown>(url: string, params?: AxiosRequestConfig): Promise<T> => {
  return api.get<Result<T>>(url, params).then(res => res.data.result)
}