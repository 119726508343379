import { useQuery } from '@tanstack/react-query'
import { ReportSchema as ReportBaseModel } from '../../../../backend/models/report.model'
import { fetch } from '../api'
import { Resource, ResourceFilters, ResourceModel, ResourcePaths, ResourceSchema } from '../omd'
import { DatesAsStrings } from '../../utils/helpers'

interface ReportModel extends Omit<ReportBaseModel, 'dates' | 'creator'>, ResourceModel {
  publicationDate: string // override we don't want it to be Date
}


export type Report = Resource<ReportModel>
export type ReportPaths = ResourcePaths<Report>
export type ReportFilters = ResourceFilters<ReportPaths>
export type ReportSchema = ResourceSchema<ReportPaths>

export const fetchReports = () => {
  return fetch<Report[]>('/reports')
}
export const fetchReportsSchema = () => {
  return fetch<ReportSchema>('/reports/schema')
}

export type ReportsQueryKey = 'reports'
export const queryKey: ReportsQueryKey = 'reports'
export type ReportsSchemaQueryKey = 'reports-schema'
export const schemaQueryKey: ReportsSchemaQueryKey = 'reports-schema'

export const useReportsQuery = () => useQuery(
  [queryKey],
  fetchReports
)
export const useReportsSchemaQuery = () => useQuery(
  [schemaQueryKey],
  fetchReportsSchema, {
  staleTime: Infinity
})

