import { allowedData } from "./ro/table"
import { allAxesToPlot } from "./ro/axes-to-plot"
import { allChartLegends } from "./ro/chart-legends"
import { OmdConfig } from "./config"
import { RoMembrane, RoMembraneAllowedPaths, roMembranesSchemaQueryPath, schemaQueryKey } from "../omd-api/membranes/ro/ro-queries"

export const roConfig: OmdConfig<RoMembrane, RoMembraneAllowedPaths> = {
  schema: {
    queryKey: schemaQueryKey,
    path: roMembranesSchemaQueryPath
  },
  table: {
    allowedData
  },
  chart: {
    axesToPlot: {
      allAxesToPlot
    },
    chartLegend: {
      allChartLegends
    }
  }
}